import React, { CCard, CCardBody, CCardTitle, CCardText } from 'react';

const About = () => {
  return (
    <div className='p-5 lg:p-10 bg-darkest lg:px-36 lg:h-96'>
      <h3 class='text-black mb-4 text-4xl font-bold lg:text-left md:text-center lg:pl-12'>
        About Us
      </h3>
      <div className='bg-lightest p-6 rounded-lg shadow-lg text-left'>
        <p className='font-normal text-gray-700 dark:text-gray-400'>
          Specializing in all academic subjects from kindergarten to university,
          Link & Learn leads, inspires and motivates students to excel
          academically by maximizing their learning potential through assessing
          students’ specific strengths, deficits and learning style, linking
          them with exceptionally qualified teachers, whose areas of expertise
          and experience will nourish students’ academic journeys, and providing
          ongoing support for all aspects of students’ studies. Our teaching
          team creates custom tailored curriculums, that are administered in a
          one on one private setting, and is ideal for students with special
          needs, especially those on the autism spectrum and those diagnosed
          with Attention Deficit Hyperactivity Disorder (ADHD). We also offer
          behaviour intervention services, focusing in on adaptive and
          functional skills for students with autism and other disabilities,
          Speech Therapy and Occupational Therapy. Additionally, our
          individualized programs are perfect for gifted children, who are
          seeking accelerated learning, as well as students enrolled at
          Calgary’s top private schools. Curriculums are also custom tailored
          for students seeking admission to the top universities in Canada, USA
          and Europe.{' '}
        </p>
      </div>
    </div>
  );
};

export default About;
