import React, { useState } from 'react';
import Footer from '../components/Footer';
import { ArrowDownIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';

const Services = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  return (
    <div>
      <div className=' p-5 lg:p-12 bg-waves bg-cover lg:px-36 lg:h-full space-y-7'>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 0.1 }}
        >
          <h3 className='text-black mb-4 text-4xl font-bold lg:text-left md:text-center lg:pl-12'>
            Services we offer
          </h3>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 0.3 }}
        >
          <Accordion
            open={open === 1}
            onClick={() => handleOpen(1)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              Customized teaching for students enrolled in Calgary’s private
              schools
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              Our teaching team will prepare students for admission to Calgary’s
              most highly acclaimed private schools and specialize in the
              curriculums offered at West Island College, Strathcona-Tweedsmuir
              School, Rundle College, Calgary Academy & Calgary Collegiate,
              Lycee International de Calgary, Foothills Academy and many others.
              Our teaching team will help students and parents prepare their
              applications, prepare and practice with students and their parents
              for the critical private interviews at the school and provide
              ongoing tutelage and support once the student has been accepted
              and enrolled. Our teachers can provide continuous guidance during
              your child’s private school education in all fundamental academic
              areas and will enable students to succeed in their highly
              competitive environments.
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 0.4 }}
        >
          <Accordion
            open={open === 2}
            onClick={() => handleOpen(2)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              Kindergarten to Grade 6 Elementary Teaching
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              We offer custom built lessons for Kindergarten to Grade 6
              elementary core subjects of English Language Arts and Literature,
              Mathematics, Science and Social Studies. Our teachers are expertly
              trained with the new 2022 Alberta K to 6 curriculum and emphasize
              the importance of the renewal of literacy, numeracy, citizenship
              and practical skills with their students. Teachers will customize
              their classes to provide enrichment for elementary pupils.
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 0.5 }}
        >
          <Accordion
            open={open === 3}
            onClick={() => handleOpen(3)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              Junior High School Teaching
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              Our tutor experts provide guidance for junior high school students
              in English Language Arts, Mathematics Science and Social Studies.
              Emphasis on assembling knowledge in these key areas as well as
              developing employability skills is prevalent throughout a
              student’s training. The goal of our Junior High School Teaching
              programs is also to provide a seamless transition into the
              fast-paced environment of Senior High School starting in Grade 10.
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 0.6 }}
        >
          <Accordion
            open={open === 4}
            onClick={() => handleOpen(4)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              Alberta Senior High School Diploma Teaching
            </AccordionHeader>
            <AccordionBody>
              <p className='text-black text-left'>
                Our highly experienced teachers can guide students to excel in
                all their academic courses required for attaining their Alberta
                High School Diploma. Teaching is offered for:
              </p>
              <ol className='list-decimal sm:text-justify pl-12 text-left'>
                <li>English 30-1 and 30-2</li>
                <li>Social Studies 30-1 and 30-2</li>
                <li>Mathematics 20-1, 20-2 and 20-3</li>
                <li>Science 20 and 24</li>
                <li>Biology 20, Chemistry 20 and Physics 20</li>
                <li>Math 30, Biology 30, Chemistry 30, and Physics 30</li>
                <li>Career and Life Management</li>
              </ol>
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 0.7 }}
        >
          <Accordion
            open={open === 5}
            onClick={() => handleOpen(5)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              International Baccalaureate Programme (IB) and Advanced Placement
              (AP)
            </AccordionHeader>
            <AccordionBody>
              <p className='text-black text-left'>
                Link & Learn offers lessons for all courses for students
                enrolled in the International Baccalaureate Programme (IB) as
                well as students enrolled in Advanced Placement (AP) courses. In
                addition to gaining invaluable guidance through all upper level
                academic courses, students enrolled in our IB teaching program
                will thrive in a one on one environment allowing them to gain
                insights from their tutor to prepare for success at university
                and life by developing intellectual, social, emotional and
                physical well-being attributes. Our teachers will prepare IB
                students for all the demanding elements of the Diploma Programme
                including the final IB examinations. For students taking
                advanced placement courses, our teachers offer classes in:{' '}
              </p>
              <ol className='list-decimal sm:text-left pl-12 text-left'>
                <li>AP Calculus</li>
                <li>AP Biology</li>
                <li>AP Chemistry</li>
                <li>AP Physics</li>
                <li>AP European History</li>
                <li>AP English Literature & Composition</li>
              </ol>
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 0.8 }}
        >
          <Accordion
            open={open === 6}
            onClick={() => handleOpen(6)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              University Admission Preparation
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              Our teachers all have a wealth of experience with university
              entrance requirements for the top tier universities in Canada, USA
              and Europe. Link & Learn can help students with all their
              university application needs ranging from guidance with writing
              their university admission essays, long term guidance with
              developing their personal profile to suit the ever changing well
              rounded demands of university requirements, recommendations and
              support for building up a student’s extra-curricular portfolio,
              preparation and practice for admission interviews and thorough
              guidance through all aspects of a student’s university application
              process.
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 0.9 }}
        >
          <Accordion
            open={open === 7}
            onClick={() => handleOpen(7)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              Specialization Working with Special Needs Students
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              Our selected teachers have substantial experience working with
              special needs students. Whether a student is on the autism
              spectrum, has varying degrees of ADHD or other learning
              disabilities, our teachers will cater a student’s classes to suit
              their specific needs and learning style and will help develop an
              individual education plan that can be used in conjunction with a
              student’s school to maximize their academic potential. We also
              offer behavioural intervention services in conjunction with a
              student’s lesson plan.
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 1 }}
        >
          <Accordion
            open={open === 8}
            onClick={() => handleOpen(8)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              Accelerated Programs and Working with Gifted Students
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              Link & Learn works with gifted students who are seeking to thrive
              in an accelerated program. Whether a student wants to work on
              accelerated Mathematics or English to graduate early from High
              School or wants to take part in academic competitions such as
              University Mathematics Contests, our specialty teachers will
              customize an accelerated curriculum for any gifted child.
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 1.1 }}
        >
          <Accordion
            open={open === 9}
            onClick={() => handleOpen(9)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              Undergraduate Level University Teaching
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              Our teachers offer guidance for all core university subjects in
              the Arts and Sciences from University of Calgary, Mount Royal
              University and other post-graduate institutions. High emphasis on
              teaching students how to prosper in a university learning
              environment takes place. Teachers offer flexible schedules and
              availability to fit a student’s busy university schedule.
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 1.1 }}
        >
          <Accordion
            open={open === 10}
            onClick={() => handleOpen(10)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              English as a Second Language (ESL)
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              Link & Learn offers a comprehensive ESL curriculum custom tailored
              for students of all grade levels. Our goal is to transition
              students into a standard English curriculum in the most efficient,
              proficient and quickest manner. Our teachers will work with
              students to develop essential fundamental written, verbal and
              reading skills while emphasizing essential aspects of the regular
              academic curriculum at the students’ school to help students work
              ahead of their peers and quickly become integrated into the
              standard curriculum for their grade level. Our ESL program is also
              custom designed for adults seeking to become proficient in the
              English language. Adults enrolled in Link & Learn’s private ESL
              training will learn practical verbal English skills that can be
              used daily and will quickly enrich their English vocabulary and
              reading comprehension skills.
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 1.1 }}
        >
          <Accordion
            open={open === 11}
            onClick={() => handleOpen(11)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              Behavior Intervention
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              Our Behaviour Intervention Service is a private 1 on 1
              non-academic service that helps students with autism and other
              disabilities to develop essential functional, adaptive and
              executive behavioural skills for essential day to day activities.
              Fundamental behaviour concepts on how to listen, how to respond to
              instructions, how to have correct and respectable body language,
              how to give personal space to others, how to ask questions and
              developing proper safe and effective methods for performing daily
              routines at home are taught. Students are brought into the
              community to socialize with their peers and taught valuable skills
              such as how to participate in group programs, how to purchase
              items from a store, how to order food in a restaurant and how to
              take transit safely. Behaviour Intervention is an essential skill
              for those on the autism spectrum who wish to learn important
              skills to transition safely into adulthood.
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 1.1 }}
        >
          <Accordion
            open={open === 12}
            onClick={() => handleOpen(12)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              Speech Therapy
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              Speech Therapy is essential for students diagnosed with a wide
              range of learning disabilities, adults suffering and recovering
              from cognitive injuries as well as adults and children who are new
              to the country and need to develop the fundamental mechanics of
              speaking the English language. Our certified speech language
              pathologist will provide an evaluation to determine your needs.
              Speech Therapy is available as both an academic and a non-academic
              service.
            </AccordionBody>
          </Accordion>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 1.1 }}
        >
          <Accordion
            open={open === 13}
            onClick={() => handleOpen(13)}
            icon={<ArrowDownIcon />}
            className='bg-white p-6 rounded-lg shadow-lg'
          >
            <AccordionHeader className='text-darkest text-2xl font-bold mb-2 sm:text-left '>
              Occupational Therapy
            </AccordionHeader>
            <AccordionBody className='text-black text-left'>
              Our Occupational Therapy service is ideal for students on the
              autism spectrum who need to work on both fine and gross motor
              skills. Those recovering from work place injuries also often seek
              out this service to help them re-learn their muscle movements in
              order to perform key activities on the job. Occupational Therapy
              is a valuable non-academic service.
            </AccordionBody>
          </Accordion>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
