import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Teachers from './pages/Teachers';
import Contact from './pages/Contact';
import Employment from './pages/Employment'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const App=()=> {
  return (
   <BrowserRouter>
   <div className='App'>
     <Navbar/>
     <Routes>
       <Route exact path = '/' element ={ <Home/> }/>
       <Route exact path = '/about' element ={ <About/> }/>
       <Route exact path = '/services' element ={ <Services/> }/>
       <Route exact path = '/teachers' element ={ <Teachers/> }/>
       <Route exact path = '/contact' element ={ <Contact/> }/>
       <Route exact path = '/work-with-us' element ={ <Employment/> }/>
     </Routes>
   </div>
  </BrowserRouter>
  );
}

export default App;
