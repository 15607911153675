import { React, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <nav className='bg-lightest'>
        <div className='max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='flex items-center justify-between h-16'>
            <nav className='flex items-center list-none'>
              <Link to='/' onClick={() => setIsOpen(false)}>
                <img
                  className='h-12 w-50 hover:bg-darkest p-2 rounded-md text-sm font-medium'
                  src='/LinkandLearnSideWhiteTrans.png'
                  alt='Workflow'
                />
              </Link>
              <ul className='hidden md:flex ml-10 space-x-4'>
                <li>
                  <Link
                    to='services'
                    className='text-gray-300 hover:bg-darkest px-3 py-2 rounded-md text-base font-medium'
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to='teachers'
                    className='text-gray-300 hover:bg-darkest px-3 py-2 rounded-md text-base font-medium'
                  >
                    Our Teachers
                  </Link>
                </li>
                <li>
                  <Link
                    to='work-with-us'
                    className='text-gray-300 hover:bg-darkest px-3 py-2 rounded-md text-base font-medium'
                  >
                    Work With Us
                  </Link>
                </li>
                <li>
                  <Link
                    to='contact'
                    className='text-gray-300 hover:bg-darkest px-3 py-2 rounded-md text-base font-medium'
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
            <div className='-mr-2 flex md:hidden'>
              <button
                onClick={() => setIsOpen(!isOpen)}
                type='button'
                className='bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-black'
                aria-controls='mobile-menu'
                aria-expanded='false'
              >
                {!isOpen ? (
                  <svg
                    className='block h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M4 6h16M4 12h16M4 18h16'
                    />
                  </svg>
                ) : (
                  <svg
                    className='block h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter='transition ease-out duration-100 transform'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='transition ease-in duration-75 transform'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          {(ref) => (
            <div className='md:hidden' id='mobile-menu'>
              <li
                ref={ref}
                className='px-2 pt-2 pb-3 space-y-1 sm:px-3 list-none'
              >
                <Link
                  to='/services'
                  className='hover:bg-darkest block px-3 py-2 rounded-md text-base font-medium'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Services
                </Link>
                <Link
                  to='/teachers'
                  className='hover:bg-darkest block px-3 py-2 rounded-md text-base font-medium'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Our Teachers
                </Link>
                <Link
                  to='/work-with-us'
                  className='hover:bg-darkest block px-3 py-2 rounded-md text-base font-medium'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Work with Us
                </Link>
                <Link
                  to='/contact'
                  className='hover:bg-darkest block px-3 py-2 rounded-md text-base font-medium'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Contact
                </Link>
              </li>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
};

export default Navbar;
