import React, { useState } from 'react';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';

const Teachers = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  return (
    <div>
      <div className='  p-5 lg:p-12 bg-waves bg-cover lg:px-36 h-full space-y-3'>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 0.1 }}
        >
          <h3 className='text-black mb-4 text-4xl font-bold lg:text-left md:text-center lg:pl-12'>
            About our Teachers
          </h3>
        </motion.div>
        <motion.div
          animate={{ y: 0 }}
          initial={{ y: 400 }}
          transition={{ delay: 0.2 }}
        >
          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <p className='text-black text-left'>
              At Link & Learn we employ the most highly qualified teachers
              specializing in English language arts, Social Studies,
              Mathematics, Science, English as a second language (ESL) and all
              other academic courses from kindergarten through to university.
              Our teachers strive to maximize the learning potential for all
              their students and create custom tailored curriculums to allow
              students to excel. We offer teachers in three categories- silver,
              gold and platinum levels. Our dedicated directors will match each
              student up with the best teachers that suit their individual goals
              and needs for every subject area. All our teachers, in conjunction
              with our directors, will hand craft and teach a customized program
              for each student.
            </p>
          </div>
        </motion.div>
        <ul className='space-y-20 md:items-center sm:p-10'>
          <motion.div
            animate={{ y: 0 }}
            initial={{ y: 400 }}
            transition={{ delay: 0.3 }}
          >
            <div className='lg:grid grid-cols-3'>
              <img
                className='mx-auto md:my-auto my-5 w-48 p-5 bg-white rounded-full border-8 border-[#C0C0C0]'
                src='/LinkandLearnSimpleSilver.png'
              />
              <div className='bg-white p-6 rounded-lg shadow-lg col-span-2'>
                <h2 className='text-black text-2xl font-bold mb-2 sm:text-left'>
                  Silver Level
                </h2>
                <p className='text-black text-left'>
                  Our Silver level teachers are specially trained in their field
                  of expertise, are currently enrolled in the senior years of
                  their university degrees, have earned scholarships and
                  accolades at their university and have significant teaching
                  experience. Silver level teachers are ideal for students in
                  kindergarten through to grade 6 as well as junior high level
                  and have experience with guiding students with special needs.
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            animate={{ y: 0 }}
            initial={{ y: 400 }}
            transition={{ delay: 0.4 }}
          >
            <div className='lg:grid grid-cols-3'>
              <img
                className='mx-auto my-5 md:my-auto w-48 p-5 bg-white rounded-full border-8 border-[#FFD700]'
                src='/LinkandLearnSimpleGold.png'
              />
              <div className='bg-white p-6 rounded-lg shadow-lg col-span-2'>
                <h2 className='text-black text-2xl font-bold mb-2 sm:text-left'>
                  Gold Level
                </h2>
                <p className='text-black text-left'>
                  Our Gold level teachers have graduated with high standing from
                  university with one or more Bachelor’s degrees in their field
                  of expertise, have many years of teaching experience, have won
                  substantial awards and scholarships while enrolled in
                  university, are specially trained to teach students with
                  special needs, including those with ADHD and those on the
                  autism spectrum, and have substantial experience creating
                  custom curriculums for gifted students seeking accelerated
                  programs. Gold level teachers are ideal for high school
                  students seeking admission to the top universities and
                  students enrolled in the top private schools in Calgary.
                  Additionally, Gold level teachers can assist students with all
                  aspects of their private school and university applications.
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            animate={{ y: 0 }}
            initial={{ y: 400 }}
            transition={{ delay: 0.5 }}
          >
            <div className='lg:grid grid-cols-3'>
              <img
                className='mx-auto md:my-auto my-5 w-48 p-5 bg-white rounded-full border-8 border-[#21B6A8]'
                src='/LinkandLearnSimplePlat.png'
              />
              <div className='bg-white p-6 rounded-lg shadow-lg col-span-2'>
                <h2 className='text-black text-2xl font-bold mb-2 sm:text-left'>
                  Platinum Level
                </h2>
                <p className='text-black text-left'>
                  Our Platinum level teachers represent the very best and most
                  qualified teachers in the industry. All our platinum teachers
                  have at least a Masters or Doctorate Degree in their
                  respective fields. Many have taught courses at the university
                  level and have received awards and accolades for their
                  contribution to research and development within the academic
                  community. Our Platinum level teachers are ideal for students
                  enrolled in the International Baccalaureate (IB) program,
                  students in advanced placement programs and students seeking
                  the best competitive edge to gain admission and scholarships
                  to the top universities in Canada, USA and Europe. Platinum
                  level teachers also specialize in teaching university level
                  courses and can guide students through all aspects of the
                  university application process. Our platinum level teachers
                  also have the most experience working with special needs
                  students, can create individual education plans for students
                  on the autism spectrum while overseeing their entire
                  development and can work closely with a student’s educational
                  psychologist to implement critical academic modifications to
                  maximize the potential and growth of a student.
                </p>
              </div>
            </div>
          </motion.div>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default Teachers;
