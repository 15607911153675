import React from 'react';

const Footer = () => {
  const footerMessage = ['© 2022 Copyright Link and Learn', <br />, 'K Chow'];
  return (
    <footer className='bg-lightest text-center lg:text-left text-sm h-fit'>
      <div className='text-gray-700 text-center p-4 bg-blue-200'>
        {footerMessage}
      </div>
    </footer>
  );
};

export default Footer;
