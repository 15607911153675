import React, { useState } from 'react';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';

const Employment = () => {
  return (
    <>
      <div className='p-5 lg:p-12 bg-waves bg-cover lg:px-36 h-screen space-y-3 lg:grid grid-cols-2'>
        <div>
          <motion.div
            animate={{ x: 0 }}
            initial={{ x: -500 }}
            transition={{ delay: 0.1 }}
          >
            <h3 className='text-black mb-4 text-4xl font-bold lg:text-left md:text-center lg:pl-12'>
              Help Build the Future With Us
            </h3>
          </motion.div>
          <div className='lg:py-4 lg:pr-12'>
            <div className='bg-white p-6 rounded-lg shadow-lg text-left flex'>
              <p className='text-black sm:text-justify'>
                Link and Learn is looking for qualified teachers with at minimum
                a Bachelor's degree in their main field of studies to teach at
                home and online classes. Education certificate preferred but not
                mandatory. Please submit resumes and cover letters to
                <a href='mailto:info@linkandlearn.ca'> info@linkandlearn.ca.</a>
              </p>
            </div>
          </div>
        </div>
        <div className='lg:pr-12'>
          <img
            className='sm:mx-auto h-auto rounded-3xl drop-shadow-2xl 2xl:mt-9 lg:mt-24'
            src='/workwithus.jpg'
            alt=''
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Employment;
