import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Hero = () => {
  return (
    <div className='p-12 bg-waves bg-cover lg:px-36 lg:h-screen space-y-3 lg:grid grid-cols-2'>
      <div className='lg:text-left md:my-auto lg:mt-16 xl:mx-8'>
        <h1 className=' tracking-tight font-extrabold text-gray-900 lg:text-4xl 2xl:text-5xl text-2xl'>
          <span className='block xl:inline'>
            Maximizing students' potential
          </span>{' '}
          <span className='block xl:inline'>
            through customized, enriched programs
          </span>
        </h1>
        <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
          Proudly serving Calgary, Chestermere, and Southern Alberta, our
          specially trained teachers create custom tailored curriculums,
          specifically designed to suit each student’s individual needs,
          learning styles, and academic persona.
        </p>
        <div className='mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start'>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className='rounded-md shadow'
          >
            <Link
              to='/contact'
              className='w-full flex items-center justify-center px-8 py-3 border border-transparent font-medium rounded-md text-white bg-darkest hover:bg-blue-200 md:py-4 md:text-lg md:px-10'
            >
              Book your Assessment Today
            </Link>
          </motion.button>
        </div>
      </div>
      <div>
        <img
          className='sm:mx-auto h-auto rounded-3xl drop-shadow-2xl 2xl:mt-9 lg:mt-24'
          src='/teaching1.jpg'
          alt=''
        />
      </div>
    </div>
  );
};

export default Hero;
