import React from 'react';
import Hero from '../components/Hero';
import About from './About';
import Footer from '../components/Footer';
import { Element } from 'react-scroll';

const Home = () => {
  return (
    <div>
      <Hero />
      <Element id='about-section' name='about-section'>
        <About />
      </Element>
      <Footer />
    </div>
  );
};

export default Home;
