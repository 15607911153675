import React, { useState, useRef } from 'react';
import Footer from '../components/Footer';
import { PhoneIcon, MailIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_zkiu2gw',
        'template_03shu99',
        form.current,
        'PXcgSBg1D_wKzwOjQ'
      )
      .then(
        (result) => {
          alert('Email sent!');
        },
        (error) => {
          alert('Oops, something went wrong. Try again');
        }
      );
  };

  return (
    <>
      <div className=' p-5 sm:p-12 bg-waves bg-cover lg:px-36 h-full space-y-3 lg:grid grid-cols-2'>
        <div className='lg:h-screen space-y-3'>
          <motion.div
            animate={{ x: 0 }}
            initial={{ x: -500 }}
            transition={{ delay: 0.1 }}
          >
            <h1 className='text-black mb-4 text-4xl font-bold lg:text-left md:text-center lg:pl-12'>
              Contact Us
            </h1>
          </motion.div>
          <motion.div
            animate={{ x: 0 }}
            initial={{ x: -500 }}
            transition={{ delay: 0.2 }}
          >
            <div className='bg-white p-6 rounded-lg shadow-lg text-left flex lg:w-2/3'>
              <PhoneIcon className='h-5 pr-5' />
              <a href='tel:+14033611111'>1-403-361-1111</a>
            </div>
          </motion.div>
          <motion.div
            animate={{ x: 0 }}
            initial={{ x: -500 }}
            transition={{ delay: 0.2 }}
          >
            <div className='bg-white p-6 rounded-lg shadow-lg text-left flex lg:w-2/3'>
              <MailIcon className='h-5 pr-5' />
              <a href='mailto:info@linkandlearn.ca'>info@linkandlearn.ca</a>
            </div>
          </motion.div>
        </div>
        <motion.div
          animate={{ x: 0 }}
          initial={{ x: 500 }}
          transition={{ delay: 0.2 }}
        >
          <div className='bg-white rounded-xl shadow-lg p-8 h-fit '>
            <form
              ref={form}
              onSubmit={sendEmail}
              className='flex flex-col space-y-4 text-left'
            >
              <div>
                <label for='' className='text-sm'>
                  Name
                </label>
              </div>
              <div>
                <input
                  type='text'
                  placeholder='Name'
                  name='from_name'
                  className='ring-1 ring-darkest w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-dark'
                />
              </div>
              <div>
                <label for='' className='text-sm'>
                  Email
                </label>
              </div>
              <div>
                <input
                  type='text'
                  placeholder='Email'
                  name='from_email'
                  pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                  className='ring-1 ring-darkest w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-dark'
                />
              </div>
              <div>
                <label for='' className='text-sm'>
                  Phone Number
                </label>
              </div>
              <div>
                <input
                  type='text'
                  placeholder='Number'
                  name='from_number'
                  className='ring-1 ring-darkest w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-dark'
                />
              </div>
              <div>
                <label for='' className='text-sm'>
                  Message
                </label>
              </div>
              <div>
                <textarea
                  type='text'
                  placeholder='Message'
                  name='message'
                  className='ring-1 ring-darkest w-full h-80 rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-dark'
                />
              </div>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                type='submit'
                className='rounded-md shadow bg-darkest text-white h-10 w-48'
                onClick={sendEmail}
              >
                Submit
              </motion.button>
            </form>
          </div>
        </motion.div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
